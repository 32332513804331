import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import ScrollAnim from 'rc-scroll-anim'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import CompeticaoCultura194 from '../components/CompeticaoCultura1984'
import Footer from '../components/Footer'
import Calendar from '../images/calendar.svg'
import ArrowDown from '../images/arrowDown.png'
import Ios from '../images/ios.svg'
import Android from '../images/android.png'
import AppMobile from '../images/app/1984-mobile.png'
import Selo from '../images/selo.png'

import { 
  header,
  bg8,
  btn6,
  title3,
  arrow,
  bg9,
  desktop,
  mobile,
} from '../components/styles/InternalPages.styles'

import {
  titlesApp,
  titleApp,
  ctaApp,
  linksApps
} from '../components/styles/EstudeAqui.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Olimpíada Pocket COMPETIÇÃO CULTURAL - 1984" />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bg8} style={{ marginTop: 84 }}>
        <div style={{ paddingTop: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection:'column' }}>
          <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
          <div css={title3} style={{ color: '#9d1216' }}>COMPETIÇÃO CULTURAL - 1984</div>
          <h2><img src={Calendar} alt='calendar' /> 05/12 a 13/12</h2>
          <h1>PREPAREM SEUS<br /> DITÓGRAFOS!</h1>
          <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn6}>ACESSE A PROVA</a>
          <ScrollAnim.Link
            to='aplicacao'
            offsetTop='120'
          >
            <div css={arrow} style={{ marginTop: 190 }}>
              Saiba mais<br />
              <img src={ArrowDown} alt='/' />
            </div>
          </ScrollAnim.Link>
        </div>
      </div>
      <div id='aplicacao' />
      <CompeticaoCultura194 />
    </div>
    <section css={[bg9, desktop]} style={{ marginTop: 60, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'column' }}>
      <div css={titlesApp} style={{ marginTop: '-100px' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
      </div>
      <div css={linksApps}>
        <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' /></a>
        <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' /></a>
      </div>
    </section>
    <section css={mobile} style={{ marginTop: 60, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'column' }}>
      <img src={AppMobile} alt='/' />
      <div style={{ textAlign: 'center', margin: '60px 0' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp} style={{ marginBottom: 24, padding: '0 24px' }}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' style={{ width: 200, marginBottom: 12 }} /></a>
          <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' style={{ width: 200 }} /></a>
        </div>
      </div>
    </section>
    <Footer />
  </Fragment>
)

export default StudentsPage
