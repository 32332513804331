import React, { Fragment } from 'react'
import Ios from '../images/ios.svg'
import Android from '../images/android.png'
import { Link } from "gatsby"
import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox,
  titlesApp,
  titleApp,
  ctaApp,
  linksApps
} from './styles/EstudeAqui.styles'

import {
  btn6,
  bg7
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={contentView}>
                <h2>
                  COMPETIÇÃO<br /> 1984
                </h2>
                <p style={{ marginBottom: 36 }}>A Olimpíada Pocket 1984 foi um sucesso. Em uma prova multidisciplinar e objetiva, trabalhamos com os participantes os mais distintos aspectos do célebre romance de George Orwell, abrangendo da política à literatura e da filosofia à sociologia.</p>
                <p style={{ marginBottom: 36 }}>A pedidos, resolvemos lançar mais uma Pocket 1984: uma Olimpíada inteiramente diferente da primeira, com uma prova de produção textual. Todos estão convidados, quer tenham participado da fase objetiva, quer não. Venha participar!</p>
                <p style={{ marginBottom: 36 }}>Como todas as olimpíadas Pocket, os participantes se envolverão em um caminho de muito aprendizado. Para isso, teremos aulas inspiradoras do nosso diletíssimo professor Carlos André, do Instituto Carlos André, que vai trabalhar vários aspectos desta grande obra. As aulas são gratuitas – e imperdíveis - e estarão disponíveis para todos os interessados no <Link to='pocketv'>canal da PockeTV!</Link> </p>
                <p style={{ marginBottom: 36 }}>Já está animado? Então, prepare-se: os 10 primeiros lugares, além do certificado de vencedor e da experiência de fazer parte de uma olimpíada inspiradora, ganharão um Kindle como premiação. Participe!</p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn6} style={{ margin: '0 auto' }}>FAÇA SUA INSCRIÇÃO</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {false && <section css={bg7} style={{ flexDirection: 'column' }}>
      <div css={titlesApp}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
      </div>
      <div css={linksApps}>
        <a href='/'><img src={Ios} alt='/' /></a>
        <a href='/'><img src={Android} alt='/' /></a>
      </div>
    </section>}
  </Fragment>
)

export default InspiredSite
